<script>
import RelatorioTemplate from "@/components/leiloes/components/relatorios/RelatorioTemplate"

export default {
  mixins: [RelatorioTemplate],
  name: 'RelatorioHtml',
  props: {
    data: {required: true},
    excel: {type: Boolean, default: false}
  },
  created() {
    console.log(this.data)
    this.isLoading = false
    this.dados = this.data
    if (this.excel) {
      this.printView.printOptions.excel = () => {
        this.$uloc.printView.emit(this.$root.wid, 'excel')
      }
    }
  }
}
</script>
