<script>
import Print from './LeilaoPrintPage'
import PrintA4 from "@/components/print/defaults/PrintA4"

export default {
  mixins: [Print],
  components: {PrintA4},
  inject: {
    printView: {
      default () {
        console.error('Main needs to be child of PrintView')
      }
    }
  },
  props: {
    orientation: {
      type: String,
      default: 'portrait'
    }
  },
  data() {
    return {
      isLoading: true,
      dados: null,
    }
  },
  computed: {},
  mounted() {
  },
  activated() {
  },
  deactivated() {
  },
  watch: {},
  methods: {}
}
</script>

<template>
  <print-a4 :orientation="orientation" :is-loading="isLoading">
    <div contenteditable="true" v-if="dados && dados.template" v-html="dados.template.template"></div>
  </print-a4>
</template>
